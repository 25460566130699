import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

window.addEventListener('load', ()=> {
    Fancybox.bind('.fancybox-video', {
        type: "iframe",
    });
});

window.addEventListener('resize', () => {

});
